import { navigate } from 'gatsby';
import qs from 'qs';
import React, { useEffect } from 'react';

import { withGuard } from 'src/components/admin/guard';
import { withConfigGuard } from 'src/components/configuration';
import Loading from 'src/components/design-system/loading';
import Recap from 'src/components/inscription/recap-reinscription';
import ContentLayout from 'src/components/layouts/content';
import { H2 } from 'src/components/layouts/content/theme';
import SEO from 'src/components/seo';

import routes from 'src/config/routes';

import useAxios from 'src/hooks/axios/useAxios';

const RecapPage = ({ location }) => {
  const { id } = location.search ? qs.parse(location.search.slice(1)) : {};
  const { requestState, load } = useAxios({
    url: routes.prebook(id),
  });

  useEffect(() => {
    if (!id) navigate('/');
    load();
  }, [id]);

  return (
    <ContentLayout>
      <SEO title="Espace adhérent - réinscription" />
      <H2>Inscription – étape 2 / 4</H2>
      <Loading requestState={requestState}>
        {(rs) => {
          const { data } = rs;
          return <Recap data={data.data} prebookId={id} />;
        }}
      </Loading>
    </ContentLayout>
  );
};

export default withGuard(
  withConfigGuard({
    redirectTo: '/famille',
    shouldRedirect: (config) =>
      !config.reinscription_ouverte && !config.reinscription_ca_ouverte,
  })(RecapPage)
);
